import { Injectable, signal } from '@angular/core';
import { DialogType } from '@components/dialog/dialog.component';
import { Nullable } from '@models/nullable.model';

export interface BaseDialogInfo {
  type?: DialogType;
  title?: string;
  action?: () => void;
  cancelAction?: () => void;
  duration?: number;
  confirmLabelButton?: string;
  cancelLabelButton?: string;
  canCloseClickingOutside?: boolean;
  color?: string;
  showCancelButton?: boolean;
}

export interface DialogViewInfo extends DialogInfo {
  open: boolean;
}

export interface SnackbarViewInfo extends BaseDialogInfo {
  open: boolean;
  message: Nullable<string>;
}

export interface DialogInfo extends BaseDialogInfo {
  message: Nullable<string | string[]>;
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private _dialogInfo = signal<Nullable<DialogInfo>>(null);

  dialogInfo;

  constructor() {
    this.dialogInfo = this._dialogInfo.asReadonly();
  }

  open(options: DialogInfo): void {
    const { type = 'snackbar', ...rest } = options;
    this._dialogInfo.set({ type, ...rest });
  }

  reset(): void {
    this._dialogInfo.set(null);
  }
}
